import { RequestHandler } from "../base/RequestHandler";
import { prompt } from "./../../Control/Vue/Notifications/Prompts";

export class Name extends RequestHandler {
	private readonly closeBtn: JQuery;
	private readonly submitBtn: JQuery;

	constructor(element: JQuery) {
		super(element);
		this.submitBtn = this.element.find(".mia-submit");
		this.submitBtn.click(this.onSubmitClick.bind(this));

		this.closeBtn = this.element.find(".close");
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onFail(res: any) {
		this.closeBtn.click();
		super.onFail(res);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onDone(res: any) {
		this.closeBtn.click();
		super.onDone(res);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onSuccess(_res: any) {
		this.closeBtn.click();
		location.reload(true);
	}

	private onSubmitClick(event: JQuery.Event) {
		event.preventDefault();
		const writtenNickname = this.element.find("input.mia-nickname").val() ?? "";
		const allowedCharactersRegEx = /^[a-zA-Z.,`' -]*$/;
		const emojiRegEx = /\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]/;
		
		if(writtenNickname.toString().length > 10 ||
			// eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
			!writtenNickname.toString().match(`(${emojiRegEx.source}|${allowedCharactersRegEx.source})`)){
			void prompt(this.element.data("nickname-invalid"));
			return;
		}
		
		this.setRequest(jQuery.post(this.submitBtn.data("url"),
			{nickname: writtenNickname}).done((response) => {
				if (response.msg) {
					void prompt(response.msg);
				}
			}));
	}
}
