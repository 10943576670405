import { HandlerType } from "../base/HandlerType";

export class ReviewVideoContentAdmin implements HandlerType {
	private readonly educationalVideoCheckbox: JQuery;
	private readonly categoryDropdown: JQuery;
	private readonly acceptButton: JQuery;

	constructor(container: JQuery) {
		this.educationalVideoCheckbox = container.find(".mia-reviewVideoContentAdminEducation");
		this.categoryDropdown = container.find(".mia-reviewVideoContentAdminCategory");
		this.acceptButton = container.find(".mia-reviewContentAccept");

		this.acceptButton.prop("disabled", this.isEducationCheckedAndSelectedCategoryNone());

		this.educationalVideoCheckbox.on("click", this.onChange.bind(this));
		this.categoryDropdown.on("change", this.onChange.bind(this));
	}

	private onChange() {
		this.acceptButton.prop("disabled", this.isEducationCheckedAndSelectedCategoryNone());
	}

	private isEducationCheckedAndSelectedCategoryNone() {
		return this.educationalVideoCheckbox.is(":checked")
			&& this.categoryDropdown.children("option:selected").val() === "-1";
	}
}
