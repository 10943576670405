var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("iframe", {
    directives: [
      {
        name: "tr",
        rawName: "v-tr",
        value: "iframe_loading",
        expression: "'iframe_loading'"
      }
    ],
    attrs: { src: _vm.src, width: _vm.width, height: _vm.height }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }