import { VueConstructor, AsyncComponent } from "vue";

import { ISwiperOptions, ISwiperBreakpointOptions, IVueListOptions } from "../VueListOptions";
import { xs, lg, md, sm } from "./../../../../../utils/ScreenSize";

import { MiaPlaza } from "../../../../../Reinforced.Typings";

// Configure display of every list template here.
const Config: {[K: string]: IVueListOptions } = {
	"admin-custom-game" : {
		backendOptions: {
			columnKeys: [
				"admincustomgame.commonid",
				"admincustomgame.play",
				"admincustomgame.status",
				"admincustomgame.localactions",
			],
		},
		component: async () => import("./AdminCustomGame.vue"),
		pageSize: 30,
	},
	"special-user" : {
		backendOptions: {
			columnKeys: [
				"specialuser.nameandnickname",
				"specialuser.specialusertype",
				"specialuser.email",
				"specialuser.deleteButton",
			]
		},
		component: async () => import("./SpecialUser.vue"),
		pageSize: 30,
	},
	"virtual-currency-transaction" : {
		backendOptions: {
			columnKeys: [
				"transaction.time", 
				"transaction.goldearned", 
				"transaction.incomehistory", 
				"transaction.type", 
				"transaction.reason", 
			],
		},
		component: async () => import("./VirtualCurrencyTransaction.vue"),
		pageSize: 50,
	},
	"top-earner" : {
		backendOptions: {
			columnKeys: [
				"topearner.name", 
				"topearner.income", 
				"topearner.goldhistory", 
				"whitespace", 
				"topearner.cheatertatus",
				"whitespace"
			],
		},
		component: async () => import("./TopEarner.vue"),
		pageSize: 10,
	},
	"competition-winner" : {
		backendOptions: {
			columnKeys: [
				"competitionwinner.winnername", 
				"competitionwinner.type", 
				"competitionwinner.date", 
				"competitionwinner.moneyearned", 
				"competitionwinner.prizename",
			],
		},
		component: async () => import("./CompetitionWinner.vue"),
		pageSize: 10,
	},
	"competition-leader" : {
		backendOptions: {
			columnKeys: [
				"competitionleader.name",
				"competitionleader.moneyearned" 
			],
		},
		component: async () => import("./CompetitionLeader.vue"),
		pageSize: 100,
	},
	"active-moderator" : {
		backendOptions: {
			columnKeys: [
				"moderator.name", 
				"moderator.locks",
				"whitespace"
			],
		},
		component: async () => import("./ActiveModerator.vue"),
		pageSize: 10,
	},
	"mohs-student" : {
		backendOptions: {
			columnKeys: [
				"mohsstudent.student",
				"mohsstudent.parent",
				"mohsstudent.sap",
				"mohsstudent.ungradedcheckpoints",
				"mohsstudent.lastlogin",
				"mohsstudent.status",
				"mohsstudent.moreoptions"
			],
		},
		component: async () => import("./MohsStudent.vue"),
		pageSize: 1000,
	},
	"admin-coupon-code" : {
		backendOptions: {
			columnKeys: [
				"couponcode.code",
				"couponcode.expiration",
				"couponcode.visitors",
				"couponcode.discounts",
				"couponcode.usage",
				"whitespace"],
		},
		component: async () => import("./AdminCouponCode.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.AdminCouponCodeList.PAGE_SIZE,
	},
	"admin-lost-pet": {
		backendOptions: {
			columnKeys: [
				"lostpet.pet",
				"lostpet.owner_name",
				"lostpet.owner_id",
				"lostpet.give_back_btn",
			],
		},
		component: async () => import("./AdminLostPet.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.AdminLostPetList.PAGE_SIZE,
	},
	"admin-member": createSwiperOptions(async () => import("./AdminMember.vue"), {
		centerInsufficientSlides: false,
		slidesPerColumn: 10,
		slidesPerView: 2,
		speed: 100,
		mousewheel: {
			invert: true,
		},
	}, {
		[md]: {
			slidesPerView: 4,
		},
		[sm]: {
			slidesPerView: 3
		},
	}),
	"admin-product-price": {
		backendOptions: {
			columnKeys: [
				"pricing.start",
				"pricing.end",
				"pricing.price"
			],
		},
		component: async () => import("./AdminProductPrice.vue"),
		pageSize: 20,
	},
	"admin-subscription-product-price": {
		backendOptions: {
			columnKeys: [
				"pricing.start",
				"pricing.end",
				"pricing.firstperiod",
				"pricing.price"
			],
			striped: true
		},
		component: async () => import("./AdminProductPrice.vue"),
		pageSize: 20,
	},
	"admin-subscription-embedded": {
		backendOptions: {
			columnKeys: [
				"subscription.creation",
				"subscription.type",
				"subscription.product",
				"subscription.config",
				"subscription.start",
				"subscription.externalid",
				"subscription.action",
			],
		},
		component: async () => import("./AdminSubscriptionEmbedded.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.AdminEmbeddedSubscriptionList.PAGE_SIZE,
	},
	"admin-text-resource": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("./AdminTextResource/AdminTextResource.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.AdminTextResourceList.PAGE_SIZE,
	},
	"article": createSwiperOptions(async () => import("./Article.vue"), {
		slidesPerView: 1,
	}, {
		[sm]: { slidesPerView: MiaPlaza.Control.Vue.Lists.ArticleList.PAGE_SIZE },
	}),
	"assessment-question": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("./AssessmentQuestion.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.AssessmentQuestionList.PAGE_SIZE,
	},
	"assign-unit": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("../../../../../curriculum/parents/lists/items/AssignUnit.vue"),
		pageSize: 10,
	},
	"assign-lesson": {
		backendOptions: {},
		component: async () => import("../../../../../curriculum/parents/lists/items/AssignLesson.vue"),
		pageSize: 10,
	},
	"assign-task-flavor": {
		backendOptions: {},
		component: async () => import("../../../../../curriculum/parents/lists/items/AssignTaskFlavor.vue"),
		pageSize: 4,
	},
	"attendance": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("./WeekLoginReport.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.WeekLoginReportList.PAGE_SIZE,
	},
	"bozo-member": {
		backendOptions: {
			columnKeys: [
				"bozomember.name",
				"bozomember.id",
				"bozomember.reviewer",
				"bozomember.creationdate",
				"bozomember.memberstate",
				"bozomember.removebozoflaglink",
			],
		},
		component: async () => import("./BozoMemberData.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.AdminBozoList.PAGE_SIZE,
	},
	"configure-curriculum-subject": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("../../../../../curriculum/parents/lists/items/ConfigureCurriculumSubject.vue"),
		pageSize: 12,
	},
	"gradebook-course": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("../../../../../gradebook/CourseList/GradebookCourse.vue"),
		pageSize: 4,
	},
	"gradebook-assignment": {
		backendOptions: {
			columnKeys: [
				"gradebookassignment.lessonname",
				"gradebookassignment.type",
				"gradebookassignment.datecompleted",
				"gradebookassignment.grade",
				"gradebookassignment.attempts",
			],
		},
		component: async () => import("../../../../../gradebook/CourseList/GradebookAssignment.vue"),
		pageSize: 20,
	},
	"gradebook-assignment-pdf": {
		backendOptions: {
			columnKeys: [
				"gradebookassignment.lessonname",
				"gradebookassignment.type",
				"gradebookassignment.datecompleted",
				"gradebookassignment.grade",
				"gradebookassignment.attempts",
			],
			responsive: false
		},
		component: async () => import("../../../../../gradebook/CourseList/GradebookAssignment.vue"),
		pageSize: 1000,
	},
	"configure-curriculum-course-enrollment": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("../../../../../curriculum/parents/lists/items/ConfigureCurriculumCourseEnrollment.vue"),
		pageSize: 30,
	},
	"subject": createSwiperOptions(async () => import("./Subject.vue"), {
		autoHeight: false,
		centerInsufficientSlides: false,
		cssClass: "mia-topOverflowVisible",
		slidesPerView: 2,
	}, {
		[sm]: { slidesPerView: 4 },
		[md]: { slidesPerView: 7 },
	}),
	"unit": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("@/lessons/lists/items/Unit.vue"),
		pageSize: 3,
	},
	"download-email": {
		backendOptions: {
			columnKeys: [
				"downloademails.id",
				"downloademails.firstname",
				"downloademails.lastname",
				"downloademails.email",
				"downloademails.children",
				"downloademails.childrenlogins",
				"downloademails.purchasecount",
				"downloademails.lastpurchase",
				"downloademails.subscriptioncreation",
			]
		},
		component: async () => import("./DownloadEmail.vue"),
		pageSize: 100,
	},
	"filmmaker": createSwiperOptions(async () => import("@/video/lists/items/Filmmaker.vue"), {
		centerInsufficientSlides: false,
		slidesPerColumn: 1,
		slidesPerView: 3,
	}, {
		[sm]: {
			slidesPerView: 6,
		},
	}),
	"friend-request": createSwiperOptions(async () => import("./FriendRequest.vue"), {
		centerInsufficientSlides: false,
		slidesPerColumn: 5,
		slidesPerView: 1,
	}, {
		[sm]: { slidesPerView: 2 },
	}),
	"arcade-game": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("./ArcadeGame.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.ArcadeGameList.PAGE_SIZE,
	},
	"give-gift": createSwiperOptions(async () => import("./Gift.vue"), {
		centerInsufficientSlides: false,
		// Set observer and observeParents to watch for visibility changes.
		// These properties will observe parent's container visibility and will refresh swiper when visibility changes.
		// If you remove these observers, swiper will become unresponsible until you change window size.
		observeParents: true,
		observer: true,
		slidesPerColumn: 3,
		slidesPerView: 2,
	}, {
		[sm]: { slidesPerView: 4 },
	}),
	"guestbook": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("./GuestBookEntry.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.GuestBookList.PAGE_SIZE,
	},
	"inventory-item": createSwiperOptions(async () => import("./Inventory.vue"), {
		centerInsufficientSlides: false,
		slidesPerColumn: 3,
		slidesPerView: 2,
	}, {
		[sm]: {
			slidesPerView: 4,
		},
	}),
	"learning-path-assigned-course": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("@/curriculum/children/lists/items/LearningPathAssignedCourse.vue"),
		pageSize: 1024,
	},
	"learning-path-completed-course": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("@/curriculum/children/lists/items/LearningPathCompletedCourse.vue"),
		pageSize: 1024,
	},
	"learning-path-completed-task": createSwiperOptions(async () => import("@/curriculum/children/lists/items/LearningPathCompletedTask.vue"), {
		centerInsufficientSlides: false,
		centeredSlides: true,
		displayAdjacentItems: false,
		slidesPerColumn: 1,
		slidesPerView: 1,
	}, {}),
	"lesson-card": createSwiperOptions(async () => import("@/lessons/lists/items/LessonCard.vue"), {
		centerInsufficientSlides: false,
		centeredSlides: false,
		displayAdjacentItems: true,
		slidesPerColumn: 1,
		slidesPerView: 1,
		spaceBetween: 8,
	}, {
		[sm]: {
			slidesPerView: 2,
		},
		[md]: {
			slidesPerView: 3,
		},
	}),
	"lesson-content-medal": createSwiperOptions(async () => import("./LessonContentPractice.vue"), {
		centerInsufficientSlides: false,
		centeredSlides: true,
		slidesPerColumn: 1,
		slidesPerView: 1,
	}, {}),
	"lesson-content-assessment": createSwiperOptions(async () => import("./LessonContentAssessment.vue"), {
		centerInsufficientSlides: false,
		centeredSlides: true,
		slidesPerColumn: 1,
		slidesPerView: 1,
	}, {}),
	"lesson-tile": {
		backendOptions: {
		},
		component: async () => import("./LessonTile.vue"),
		pageSize: 4,
	},
	"lesson-content-video": createSwiperOptions(async () => import("./LessonContentVideo.vue"), {
		centerInsufficientSlides: false,
		centeredSlides: true,
		slidesPerColumn: 1,
		slidesPerView: 1,
	}, {}),
	"lesson-content-supplemental": createSwiperOptions(async () => import("./LessonContentSupplemental.vue"), {
		centerInsufficientSlides: false,
		centeredSlides: true,
		slidesPerColumn: 1,
		slidesPerView: 1,
	}, {}),
	"lesson-search": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("@/lessons/lists/items/LessonCard.vue"),
		pageSize: 16,
	},
	"member": createSwiperOptions(async () => import("./Member.vue"), {
		slidesPerColumn: 1,
		slidesPerView: 1,
	}, {
		[480]: {slidesPerView: 2},
		[sm]: {slidesPerView: 3},
	}),
	"teamMember": createSwiperOptions(async () => import("./Member.vue"), {
		slidesPerColumn: 1,
		slidesPerView: 1,
	}, {
		[480]: {slidesPerView: 2},
		[sm]: {
			slidesPerView: 2,
			adjacentItemPortion: 0.5,
		}
	}),
	"member-block-list": createSwiperOptions(async () => import("./ActionableMemberCard.vue"), {
		slidesPerColumn: 1,
		slidesPerView: 1,
	}, {
		[sm]: { slidesPerView: 4 },
	}),
	"member-unfriend-list": createSwiperOptions(async () => import("./ActionableMemberCard.vue"), {
		slidesPerColumn: 1,
		slidesPerView: 1,
	}, {
		[sm]: { slidesPerView: 4 },
	}),
	"message": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("./Message.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.MessageList.PAGE_SIZE,
	},
	"mini-world-draft": createSwiperOptions(async () => import("@/mini-world/shop/designed-item/lists/items/DraftItem.vue"), {
		centerInsufficientSlides: false,
		slidesPerColumn: 2,
		slidesPerView: 2,
	}, {
		[sm]: { slidesPerView: 6 },
	}),
	"designed-item-more": createSwiperOptions(async () => import("@/mini-world/shop/designed-item/lists/items/DesignedItem.vue"), {
		slidesPerColumn: 2,
		slidesPerView: 3,
		spaceBetween: 10,
		useTransitionOnEmptying: false
	}, {
		[sm]: { slidesPerView: 5 },
		[md]: { slidesPerView: 6 },
		[lg]: { slidesPerView: 8 },
	}),
	"design-template": createSwiperOptions(async () => import("@/mini-world/shop/designed-item/lists/items/DesignTemplate.vue"), {
		slidesPerColumn: 3,
		slidesPerView: 2,
		spaceBetween: 12,
		useTransitionOnEmptying: false
	}, {
		[sm]: { slidesPerView: 3 },
		[md]: { slidesPerView: 4 },
		[lg]: { slidesPerView: 5 },
	}),
	"designed-item": createSwiperOptions(async () => import("@/mini-world/shop/designed-item/lists/items/DesignedItem.vue"), {
		slidesPerColumn: 2,
		slidesPerView: 2,
		spaceBetween: 16,
		useTransitionOnEmptying: false
	}, {
		[sm]: { slidesPerView: 3 },
		[md]: { slidesPerView: 4 },
		[lg]: { slidesPerView: 5 },
	}),
	"designed-item-vertical": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("@/mini-world/shop/designed-item/lists/items/DesignedItem.vue"),
		pageSize: 10,
	},
	"more-video": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("@/video/lists/items/Video.vue"),
		pageSize: 10,
	},
	"newsletter-content" : {
		backendOptions: {
			columnKeys: [
				"admin_newsletter_is_on_parent_portal",
				"newslettercontent.creation_date",
				"newslettercontent.subject",
				"newslettercontent.status",
				"newslettercontent.progress",
				"newslettercontent.action",
				"admin_newsletter_duplicate",
				"newslettercontent.edit_review",
				"newslettercontent.delete",
			],
		},
		component: async () => import("./AdminNewsletterContent.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.AdminNewsletterContentList.PAGE_SIZE,
	},
	"newspaper-article": {
		backendOptions: {},
		component: async () => import("./NewspaperArticle.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.NewspaperArticleList.PAGE_SIZE,
	},
	"newspaper-preview": createSwiperOptions(async () => import("./NewspaperPreview.vue"), {
		autoHeight: true,
		slidesPerColumn: MiaPlaza.Control.Vue.Lists.NewspaperArticleList.PAGE_SIZE,
		slidesPerView: 1,
	}, {
		[sm]: { slidesPerView: 2 },
	}),
	"pdf-page": {
		backendOptions: {},
		component: async () => import("@/lessons/lists/items/PdfPage.vue"),
		pageSize: 1,
	},
	"report": {
		backendOptions: {
			columnKeys: []
		},
		component: async () => import("./ReportCourse.vue"),
		pageSize: 5,
	},
	"assessment-attempt": {
		backendOptions: {
			columnKeys: [
				'assessment-attempt.finished',
				'assessment-attempt.score'
			]
		},
		component: async () => import("./AssessmentAttempt.vue"),
		pageSize: 10,
	},
	"report-task": {
		backendOptions: {},
		component: async () => import("./ReportTask.vue"),
		pageSize: 10,
	},
	"configure-custom-break": {
		backendOptions: {},
		component: async () => import("@/curriculum/parents/lists/items/ConfigureCustomBreak.vue"),
		pageSize: 4,
	},
	"configure-public-holiday": {
		backendOptions: {},
		component: async () => import("@/curriculum/parents/lists/items/ConfigurePublicHoliday.vue"),
		pageSize: 5,
	},
	"schedule-day": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("@/curriculum/parents/lists/items/ScheduleDay.vue"),
		pageSize: 2,
	},
	"schedule-task": {
		backendOptions: {},
		component: async () => import("@/curriculum/parents/lists/items/ScheduleTask.vue"),
		pageSize: 5,
	},
	"schedule-unit": {
		backendOptions: {},
		component: async () => import("@/curriculum/parents/lists/items/ScheduleUnit.vue"),
		pageSize: 3,
	},
	"search-schedule-task": {
		backendOptions: {},
		component: async () => import("@/curriculum/parents/lists/items/SearchScheduleTask.vue"),
		pageSize: 10,
	},
	"selectable-week": createSwiperOptions(async () => import("@/curriculum/parents/lists/items/SelectableWeek.vue"), {
		cssClass: "selectable-swiper-container",
		displayAdjacentItems: false,
		mousewheel: {
			invert: true,
		},
		slidesPerColumn: 1,
		slidesPerView: 1,
		spaceBetween: 0,
	}, {
		[sm]: { slidesPerView: 2 },
		[md]: { slidesPerView: 3 },
		[lg]: { slidesPerView: 4 },
	}),
	"shape": createSwiperOptions(async () => import("@/mini-world/shop/lists/items/Shape.vue"), {
		autoHeight: false,
		slidesPerColumn: 1,
		centerInsufficientSlides: false,
		slidesPerView: 3,
		spaceBetween: 3,
	}, {
		[sm]: { slidesPerView: 7 },
		[lg]: { slidesPerView: 8 },
	}),
	"team-member": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("@/public/site-about/lists/items/TeamMember.vue"),
		pageSize: 30,
	},
	"tutorial-video": {
		backendOptions: {},
		component: async () => import("@/Control/Vue/TutorialVideos/TutorialVideo.vue"),
		pageSize: 100,
	},
	"shoutbox": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("@/shoutbox/lists/items/ShoutBox.vue"),
		pageSize: 15,
	},
	"team": createSwiperOptions(async () => import("./Team.vue"), {
		autoHeight: true,
		slidesPerColumn: MiaPlaza.Control.Vue.Lists.TeamList.PAGE_SIZE,
		slidesPerView: 1,
		displayAdjacentItems: false,
	}, {}),
	"testimonial": createSwiperOptions(async () => import("./Testimonial.vue"), {
		slidesPerView: 1,
		cssClass: "mia-TestimonialsSwiperContainer",
		slidesOffsetAfter: 24,
		slidesOffsetBefore: 24,
		spaceBetween: 24
	}, {
		[xs]: { spaceBetween: 16 },
		[sm]: { slidesPerView: 2 },
		[md]: { slidesPerView: 3 },
	}),
	"transaction": createSwiperOptions(async () => import("./Transaction.vue"), {
		autoHeight: true,
		slidesPerColumn: MiaPlaza.Control.Vue.Lists.TransactionList.PAGE_SIZE,
		slidesPerView: 1,
	}, {
		[sm]: { slidesPerView: 2 },
	}),
	"uploaded-image": {
		backendOptions: {
			columnKeys: [
				"uploadedimage.id",
				"uploadedimage.image",
				"uploadedimage.path_on_disk",
				"uploadedimage.date_of_creation",
				"uploadedimage.delete",
				"uploadedimage.open",
			],
		},
		component: async () => import("./AdminUploadedImage.vue"),
		pageSize: MiaPlaza.Control.Vue.Lists.AdminUploadedImageList.PAGE_SIZE,
	},
	"category-answered-questions": {
		backendOptions: {},
		component: async () => import("@/Control/Vue/Lists/VueList/Items/CategoryAnsweredQuestions.vue"),
		pageSize: 10,
	},
	"answered-question": {
		backendOptions: {},
		component: async () => import("@/Control/Vue/Lists/VueList/Items/AnsweredQuestion.vue"),
		pageSize: 30,
	},
	"video": createSwiperOptions(async () => import("@/video/lists/items/Video.vue"), {
		autoHeight: false,
		centerInsufficientSlides: false,
		slidesPerColumn: 1,
		slidesPerView: 1,
	}, {
		[sm]: {
			slidesPerView: 3,
		},
	}),
	"video-search-results": {
		backendOptions: {
			distance: 1080 / 2,
		},
		component: async () => import("@/video/lists/items/VideoBarDetailed.vue"),
		pageSize: 10,
	},
	"assign-course": {
		backendOptions: {},
		component: async () => import("@/curriculum/parents/lists/items/AssignCourse.vue"),
		pageSize: 30,
	},
	"mini-world-house": {
		backendOptions: {},
		component: async () => import("@/Control/Vue/MiniWorld/MiniWorldHouse.vue"),
		pageSize: 6,
	},
	"team-achievements": {
		backendOptions: {},
		component: async () => import("@/Control/Vue/TeamAchievements/TeamAchievements.vue"),
		pageSize: 5,
	},
	"ranked-list-item": {
		backendOptions: {},
		component: async () => import("@/Control/Vue/Lists/VueList/Items/RankedList.vue"),
		pageSize: 20,
	},
	"design-item": createSwiperOptions(async () => import("./DesignedItem.vue"), {
		centerInsufficientSlides: false,
		slidesPerColumn: 3,
		slidesPerView: 2,
	}, {
		[sm]: { slidesPerView: 4 },
	})
};
export default Config;

function createSwiperOptions(
	component: VueConstructor | AsyncComponent,
	options: Partial<ISwiperOptions>,
	breakpoints: { [K: number]: ISwiperBreakpointOptions }): IVueListOptions {

	const backendOptions = {
		autoHeight: true,
		breakpointsInverse: true,
		centerInsufficientSlides: true,
		centeredSlides: false,
		direction: "horizontal",
		displayAdjacentItems: true,
		grabCursor: true,
		includeNavigationButtons: true,
		slidesPerColumn: 1,
		slidesPerView: 3,
		spaceBetween: 20,
		...options,
	} as ISwiperOptions;
	backendOptions.breakpoints = breakpoints;
	return {
		backendOptions,
		component,
		pageSize: backendOptions.slidesPerColumn * getMaxSlidesPerView(backendOptions),
	};
}

export function getMaxSlidesPerView(options: ISwiperOptions): number {
	return Math.max(options.slidesPerView || 1, ...Object.values(options.breakpoints || {})
		.map((config) => config.slidesPerView || 1));
}
